import * as React from 'react';
import { ICalculatorFormProps } from './ICalculatorFormProps';

import useFetchWithMsal from '../../react-hooks/useFetchWithMsal';
import { IMsalContext, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { g_protectedResources } from '../../authConfig';
import { IState, useState } from '../../react-hooks/useState';
import { 
    FormContext,
    FormClass,
    IResultValues,
    SectionKeys,
    IGeneralInfoValues,
    IHeatingCircutsValues,
    IHeatpumpValuesForForm
} from './FormContext';
import { StepperForm } from './StepperForm/StepperForm';
import { IDevices, DevicesContext } from './DevicesContext';
import { Loader } from '../Loader/Loader';
import { IHeatpumpSelectionValues, IProjectData } from './IProjectData';
import { IActiveAccount } from '../../models/IActiveAccount';
import { g_getActiveUser } from '../../helpers/msalHelper';
import { ITranslationsContext, TranslationsContext } from '../../translations/TranslationsContext';
import { g_infoToast, g_successToast } from '../../helpers/toastHelper';
import { Format } from '../../helpers/Format';
import { IRefState, useRefState } from '../../react-hooks/useRefState';
import * as _ from 'lodash';
import { ResultProjectContext } from './Result/Result';

/**
 * A cache variable to hold the devices between the Form instances.
 * Needed so you wont need to reload the devices all the time
 */
let g_devicesCache:IDevices|null = null;

/**
 * Renders out the calculator form
 */
export function CalculatorForm(props:ICalculatorFormProps) {

    const _translations:ITranslationsContext|null = React.useContext(TranslationsContext);
    const _isAuth:boolean = useIsAuthenticated();
    const _msal:IMsalContext = useMsal();
    const _user:IActiveAccount = g_getActiveUser(_msal);

    const _msalGetProject = useFetchWithMsal(g_protectedResources.apiAllDevices.scopes.generator);
    const _msalGetDevices = useFetchWithMsal(g_protectedResources.apiAllDevices.scopes.generator);

    const _resultProject:IState<IProjectData|null> = useState<IProjectData|null>(null);

    /**
     * The project that has been loaded in from the props.
     * If NULL then shows that the project needs to be loaded in.
     */
    const _loadedInProject:IState<IProjectData|null> = useState<IProjectData|null>(null);    

    /**
     * Returns the default form settings where the project info is filled in from the profile
     */
    const _getDefaultForm = ():FormClass => {
        const ret:FormClass = new FormClass(g_devicesCache);
        const generalInfo:Partial<IGeneralInfoValues> = {
            projectDrafter: _user.projectDrafter,
            projectManager: _user.projectManager,
            accountableProjectDrafter: _user.accountableProjectDrafter,
            projectNumber: _user.projectNumber
        };
        ret.generalInfo.setValues(generalInfo);
        ret.hasChanges = false;
        return ret;
    };
    /**
     * Holds the form reference
     */
    const _form:IRefState<FormClass> = useRefState<FormClass>(new FormClass(null));
    React.useEffect(() => {
        _form.set(_getDefaultForm());
    }, []);

    //fetches the devices if missing
    React.useEffect(() => {

        if(!g_devicesCache             
            && _isAuth 
            && !_msalGetDevices.isLoading.ref.current //not already loading
            && !_msalGetDevices.error.ref.current //not in error
        ) {
            _msalGetDevices.execute('POST', g_protectedResources.apiAllDevices.endpoint, false).then((result:IDevices) => {
                if(result) {
                    g_devicesCache = result;
                    const form:FormClass = new FormClass(g_devicesCache, _form.ref.current);
                    form.checkDeviceSettings();
                    _form.set(form);
                }
            });
        }
    }, [
        g_devicesCache, 
        _isAuth, 
        _msalGetDevices
    ]);

    //loads in the project data if needed
    React.useEffect(() => {
        if(props.project 
            && _loadedInProject.value?.guid !== props.project?.guid
            && _isAuth 
            && !_msalGetProject.isLoading.ref.current //not already loading
            && !_msalGetProject.error.ref.current //not in error
        ) {
            _msalGetProject.execute(
                'POST', 
                g_protectedResources.apiGetProject.endpoint, 
                props.project,
                true
            ).then((result:IProjectData) => {
                if(props.project?.guid !== result?.guid || !g_devicesCache) {
                    //safty check so we would not load in wrong data
                    return;
                }

                const form:FormClass = new FormClass(g_devicesCache);
                form.generalInfo.setValues(result.generalInfo);
                form.heatingCircuts.setValues(result.heatingCircuts);
                form.coolingCircuts.setValues(result.coolingCircuts);
                form.heatpump.setValues({
                    ...result.heatpump,
                    singlePumpViewPump: result.heatpump.type //makes sure the selected pump is visible in the single pump view
                });
                form.tanksSelection.setValues(result.tanks);
                
                //for clcned projects we do not set the result, we expect the end user to generate it them selves
                if(!props.isCloneOfProject) {
                    const newResultValues:Partial<IResultValues> = {                    
                        generationResult: {
                            fileName: result.result?.fileName || '',
                            projectGuid: result.guid,
                            projectId: result.id as string,
                            userId: result.user.id as string
                        },
                        isEnabled: true,
                        projectGuid: result.guid,
                        projectId: result.id
                    };
    
                    form.result.setValues(newResultValues);
                    form.setSection(SectionKeys.result);

                    _resultProject.set(_.cloneDeep(result));
                }
                else {
                    g_infoToast(Format.text(_translations?.phrases.successfullyClonedMessage, form.generalInfo.values.projectName));                    
                }

                form.isFormInError();
                form.hasChanges = false;

                _form.set(form);

                _loadedInProject.set(result);
            });
        }
    }, [
        props.project,
        _isAuth, 
        _msalGetProject,
        props.project?.guid,
        g_devicesCache
    ]);

    if(
        _msalGetDevices.isLoading.value 
        || _msalGetProject.isLoading.value
    ) {
        return <Loader />
    }
    
    return (
        <>
            <FormContext.Provider value={_form}>
                <DevicesContext.Provider value={g_devicesCache}>
                    <ResultProjectContext.Provider value={_resultProject}>
                        <StepperForm 
                            onHomeClick={props.onHomeClick}
                        />
                    </ResultProjectContext.Provider>
                </DevicesContext.Provider>
            </FormContext.Provider>
        </>
    );
}